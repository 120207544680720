import React from 'react';

import { Root, Content, Svg } from './modal.styled';

export default function Modal ({ isOpen, onClose, children }) {
    if (!isOpen) return null;

    return (
        <Root>
            <Content>
                {children}
                <Svg onClick={onClose} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                    <path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM175 175c9.4-9.4 24.6-9.4 33.9 0l47 47 47-47c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9l-47 47 47 47c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0l-47-47-47 47c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l47-47-47-47c-9.4-9.4-9.4-24.6 0-33.9z"/>
                </Svg>
            </Content>
        </Root>
    );
};